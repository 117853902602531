import { useState, useEffect, useRef } from 'react';

function YTPlayer(props) {
  const { className, videoID, startSeconds, onPaused, onEnded } = props;
  const [player, setPlayer] = useState(null);
  const ref = useRef();

  useEffect(() => {
    if (!videoID) { return; }

    if (player) {
      console.log(player);
      player.loadVideoById({
        videoId: videoID,
        startSeconds: startSeconds ?? 0,
      });
    }

    if (typeof YT === 'undefined') {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);

      window.onYouTubeIframeAPIReady = () => {
        setupPlayer();
      };
    } else {
      setupPlayer();
    }
  }, [videoID]);

  function setupPlayer() {
    // eslint-disable-next-line no-undef
    new YT.Player(ref.current, {
      videoId: videoID,
      playerVars: { autoplay: 1, controls: 0 },
      events: {
        onReady: handleReady,
        onStateChange: handleStateChange,
      },
    });
  }

  function handleReady(ev) {
    setPlayer(ev.target);
    console.log(ev.target);
    console.log('handleReady');
    if (startSeconds) {
      ev.target.seekTo(startSeconds, true);
    }
  }

  function handleStateChange(ev) {
    // eslint-disable-next-line no-undef
    if (ev.data === YT.PlayerState.PAUSED) {
      console.log('onPaused');
      onPaused?.();
    }
    // eslint-disable-next-line no-undef
    if (ev.data === YT.PlayerState.ENDED) {
      console.log('onEnded');
      onEnded?.();
    }
  }

  return (
    <div ref={ref} className={className} />
  );
}

export default YTPlayer;
