import { useState, useEffect, useRef } from 'react';

import { API_BASE_URL } from './env';
import YTPlayer from './YTPlayer';

function App() {
  const [queue, setQueue] = useState([]);
  const [queueID, setQueueID] = useState('');
  const [videoID, setVideoID] = useState('');
  const [startSeconds, setStartSeconds] = useState(0);

  useEffect(() => {
    refresh();
    setInterval(refresh, 10000);
  }, []);

  async function refresh() {
    console.log('refresh');

    setQueue(await fetch(`${API_BASE_URL}/queue`).then(res => res.json()));
    const result = await fetch(`${API_BASE_URL}/current`).then(res => res.json());
    if (!result.id) { return; }
    setQueueID(result.id);

    const { videoID } = result.services[0];
    if (result.startTime < Date.now()) {
      setVideoID(videoID);
    } else {
      setTimeout(() => { setVideoID(videoID) }, result.startTime - Date.now());
    }
    setStartSeconds(Math.max(Date.now() - result.startTime, 0) / 1000);
  }

  return (
    <div className="h-screen flex flex-col dark:bg-slate-800 dark:text-white">
      <div className='grow flex'>
        <YTPlayer
          className='grow'
          videoID={videoID}
          startSeconds={startSeconds}
          onEnded={() => { refresh(); }}
        />
        <QueueList
          queue={queue}
          currentQueueID={queueID}
        />
      </div>
      <Postbox onAdd={ () => { refresh(); } } />
    </div>
  );
}

function QueueList(props) {
  const { queue, currentQueueID } = props;

  return (
    <div className='w-[800px] overflow-y-auto'>
      {queue.map(({ id, title, autoplay }) => (
        <div key={id}>{autoplay ? '[autoplay] ' : ''}{title}</div>
      ))}
    </div>
  );
}

function Postbox(props) {
  const { onAdd } = props;
  const inputRef = useRef();

  async function onSubmit() {
    if (!inputRef.current.value) { return; }

    await fetch(`${API_BASE_URL}/queue`, {
      method: 'POST',
      body: JSON.stringify({ url: inputRef.current.value }),
      headers: { 'Content-Type': 'application/json' },
    });

    inputRef.current.value = '';

    onAdd?.();
  }

  return (
    <div className='p-3 flex gap-3'>
      <input
        className='grow px-3 py-1 border-2 rounded-lg placeholder:italic dark:bg-slate-600'
        placeholder='Input Youtube video URL'
        ref={inputRef}
      />
      <button
        className='px-3 py-1 text-white bg-indigo-600 rounded-lg hover:shadow-xl'
        onClick={onSubmit}>
        Add queue
      </button>
    </div>
  );
}

export default App;
